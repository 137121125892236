.animated-text-container {
    text-align: center;
    padding: 20px;
  }
  
  .animated-text {
    font-size: 18px;
    font-weight: light;
    color: #fff;
    animation: blink-animation 1.5s steps(5, start) infinite;
  }
  
  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  